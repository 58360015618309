import { lazy, Suspense, useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import routes from "./config";
import { Styles } from "../styles/styles";

const Router = () => {
  const [headerText, setHeaderText] = useState("");
  const getJobId = () => {
    try {
      const params = new URLSearchParams(window.location.search);
      // Access the parameters
      const jobId = params.get("jobId"); // "123"
      if (jobId) {
        setHeaderText(
          `Thanks for your order! Please note your order number: ${jobId}.`
        );
      }
    } catch (e) {}
  };
  useEffect(() => {
    getJobId();
  }, []);
  return (
    <Suspense fallback={null}>
      <div
        style={{
          width: "100%",
          marginTop: "20px",
          justifyItems: "center",
          textAlign: "center",
        }}
      >
        {headerText}
      </div>
      <Styles />
      <Header headerText={headerText} />

      <Switch>
        {routes.map((routeItem) => {
          return (
            <Route
              key={routeItem.component}
              path={routeItem.path}
              exact={routeItem.exact}
              component={lazy(() => import(`../pages/${routeItem.component}`))}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
};

export default Router;
